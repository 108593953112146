import PropTypes from 'prop-types';
import Glide from '@glidejs/glide';
import React, { useEffect, useRef } from 'react';

// Material-UI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// Components
import Link from 'components/Link/Link';
import CustomImage from 'components/CustomImage';

// Const
import { LIST_ITEM_FALLBACK } from 'constants/images-defaults';
import useAuth from 'hooks/useAuth';

const IMAGEREG = /(^\w+:|^)\/\//;

const styles = {
  carousel: {
    px: '50px',
    mb: 2,
  },
  carousel__container: {
    position: 'relative',
  },
  glide_arrows: {
    width: '100%',
    height: '100%',
    zIndex: 1,
    '& Button': {
      zIndex: 1,
      width: 2.5,
      height: 'initial',
      border: 'none',
      top: 'calc(50% - 35px)',
      borderRadius: '50%',
      cursor: 'pointer',
      boxShadow: 'none',
      outline: 'none',
      color: 'black',
      disableElevation: true,
      opacity: 0.5,
      transition: 'all 0.3s ease-in-out',
      '&:hover': {
        opacity: 1,
        backgroundColor: 'transparent',
      },
    },
    '& Button:first-of-type': {
      marginRight: { xs: -1.5, md: -2 },
    },
    '& Button:last-child': {
      marginLeft: { xs: -1.5, md: -2 },
    },
  },
  glide__arrow: {
    position: 'absolute',
    display: 'block',
    textTransform: 'uppercase',
    padding: '9px 12px',
    backgroundColor: 'transparent',
    textShadow: '0 .25em .5em rgba(0, 0, 0, .1)',
    transform: 'translateY(-50 %)',
    lineHeight: 1,
  },
  glide__arrows__unregistered: {
    width: '100%',
    height: '100%',
    zIndex: 1,
    '& Button': {
      zIndex: 1,
      width: 2.5,
      height: 'initial',
      border: 'none',
      top: 'calc(50% - 35px)',
      borderRadius: '50%',
      cursor: 'pointer',
      boxShadow: 'none',
      outline: 'none',
      color: 'black',
      disableElevation: true,
      opacity: 0.5,
      transition: 'all 0.3s ease-in-out',
      '&:hover': {
        opacity: 1,
        backgroundColor: 'transparent',
      },
    },
    '& Button:first-of-type': {
      marginRight: { xs: -1, md: 1 },
    },
    '& Button:last-child': {
      marginLeft: { xs: -1, md: 1 },
    },
  },
  carousel__list: {
    overflow: 'hidden',
  },
  carousel__element: {
    textAlign: 'center',
    mt: 0.5,
  },
};

const carouselConfiguration = {
  type: 'slider',
  gap: 12,
  startAt: 0,
  keyboard: true,
  bound: true,
  focusAt: 0,
  arrows: {
    prev: 'carousel__prev',
    next: 'carousel__next',
  },
  perView: 6,
  breakpoints: {
    2000: {
      // Desktop
      perView: 4,
    },
    1200: {
      // laptop
      perView: 3,
    },
    992: {
      // tablet landscape
      perView: 3,
    },
    768: {
      // tablet
      perView: 2,
    },
    576: {
      // mobile
      perView: 1,
    },
  },
};

const carouselDefaultData = [
  {
    id: -1,
    name: '',
    url: '',
  },
];

const GlideCarousel = ({ carouselData = carouselDefaultData, textColor = 'black', reference = '' }) => {
  const glideEl = useRef(null);
  const isAuthenticated = useAuth();

  useEffect(() => {
    if (glideEl?.current) {
      new Glide(glideEl?.current, carouselConfiguration).mount();
    }
  }, []);

  return (
    <Box className="glide" sx={styles.carousel} ref={glideEl}>
      <Box
        className="glide__arrows"
        data-glide-el="controls"
        sx={reference === 'categories' && !isAuthenticated ? styles.glide__arrows__unregistered : styles.glide_arrows}
      >
        <Button
          disableRipple
          type="button"
          className="glide__arrow glide__arrow--left"
          sx={styles.glide__arrow}
          data-glide-dir="<"
        >
          <ArrowBackIosIcon />
        </Button>
      </Box>
      <Box className="glide__track" data-glide-el="track" sx={styles.carousel__container}>
        <Box className="glide__slides" sx={styles.carousel__list}>
          {carouselData.map(({ name, image, url, id }) => (
            <Box className="glide__slide" key={id}>
              <Link aria-label={name} underline="none" href={url} passHref>
                <CustomImage
                  src={image?.replace(IMAGEREG, '')}
                  width={300}
                  height={300}
                  alt={name}
                  fallbackSrc={LIST_ITEM_FALLBACK}
                  objectFit="cover"
                />
              </Link>
              <Box className="glide__slide-content" sx={styles.carousel__element}>
                <Link aria-label={name} underline="none" href={url} passHref>
                  <Typography variant="body1" component="h2" color={textColor}>
                    {name}
                  </Typography>
                </Link>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        className="glide__arrows"
        data-glide-el="controls"
        sx={reference === 'categories' && !isAuthenticated ? styles.glide__arrows__unregistered : styles.glide_arrows}
      >
        <Button
          disableRipple
          type="button"
          className="glide__arrow glide__arrow--right"
          sx={styles.glide__arrow}
          data-glide-dir=">"
        >
          <ArrowForwardIosIcon />
        </Button>
      </Box>
    </Box>
  );
};

GlideCarousel.propTypes = {
  carouselData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      url: PropTypes.string,
    })
  ),
};

export default GlideCarousel;
