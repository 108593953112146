import { useRef } from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';

// Material-UI
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';

/** Components */
import CustomImage from 'components/CustomImage';
import { CarouselDots } from 'components/carousel';

// Hooks
import useResponsive from 'hooks/useResponsive';

const PRESSRELEASES = [
  {
    id: 1,
    imgSrc: '/assets/images/homepage/press-releases/techcrunch-logo.webp',
    altText: 'TechCrunch Press Release',
    articleLink:
      'https://techcrunch.com/2021/08/03/independent-retailer-platform-creoate-raises-5m-seed-led-by-fuel-ventures/',
    text: '"Helping independent businesses reclaim the supply chain from global giants"',
    width: 215.98,
    height: 74.39,
  },
  {
    id: 2,
    imgSrc: '/assets/images/homepage/press-releases/uktn-logo.webp',
    altText: 'UKTN Press Release',
    articleLink:
      'https://www.uktech.news/news/this-aspiring-female-founder-raised-35m-from-mark-pearsons-fuel-ventures-for-her-b2b-wholesale-marketplace-20210803',
    text: '"Creoate´s UK retailer network has 4.5x more locations than Boots, Marks and Spencer and John Lewis combined"',
    width: 215.98,
    height: 74.39,
  },
  {
    id: 3,
    imgSrc: '/assets/images/homepage/press-releases/eustartups-logo.webp',
    altText: 'EUStartups Press Release',
    articleLink:
      'https://www.eu-startups.com/2021/08/london-based-wholesale-marketplace-creoate-nabs-e4-2-million-to-support-independent-retailers/',
    text: '"This advanced platform is being adopted at an exponential rate"',
    width: 215.98,
    height: 74.39,
  },
];

const RootStyle = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(3),
  backgroundColor: '#FFFFFF',
  color: theme.palette.primary.main,
}));

const MobileCarousel = () => {
  const carouselRef = useRef(null);

  const settings = {
    arrows: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 10000,
    dots: true,
    ...CarouselDots({
      rounded: true,
      sx: { mt: 3 },
    }),
    centerMode: false,
    centerPadding: '0px',
    responsive: [
      {
        breakpoint: 480,
        settings: { slidesToShow: 1 },
      },
    ],
  };

  return (
    <Box sx={{ position: 'relative', maxWidth: '700px', alignContent: 'center' }}>
      <Slider ref={carouselRef} {...settings}>
        {PRESSRELEASES.map((pressrelease) => (
          <Box key={pressrelease.id}>
            <PressReleasesCard pressreleases={pressrelease} />
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

const pressReleasesCardDefaultPropPressreleases = {
  id: '',
  text: '',
  imgSrc: '',
  altText: '',
  width: '',
  height: '',
  articleLink: '',
};

const PressReleasesCard = ({ pressreleases = pressReleasesCardDefaultPropPressreleases }) => {
  const { id, imgSrc, altText, width, height, text, articleLink } = pressreleases;

  return (
    <Grid key={id} item xs={12} md={4}>
      <Card align="center" elevation={0} sx={{ mt: { xs: 2, md: 3 }, backgroundColor: 'transparent' }}>
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <CustomImage
          src={imgSrc}
          alt={altText}
          width={width}
          height={height}
          sx={{
            objectfit: 'cover',
            objectPosition: 'center',
            borderRadius: '4px',
          }}
          unoptimized
        />
        <Box
          sx={{
            textAlign: 'center',
            backgroundColor: 'transparent',
            borderRadius: '4px',
          }}
        >
          <Typography component="h4" variant="subtitle1" sx={{ color: 'primary', fontWeight: 'bold' }}>
            {text}
          </Typography>
          <Button
            variant="text"
            color="primary"
            href={articleLink}
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              mt: 2,
              textTransform: 'none',
              fontWeight: 'bold',
              color: 'primary',
            }}
            endIcon={<DoubleArrowIcon fontSize="small" />}
          >
            READ MORE
          </Button>
        </Box>
      </Card>
    </Grid>
  );
};

PressReleasesCard.propTypes = {
  pressreleases: PropTypes.shape({
    id: PropTypes.string,
    text: PropTypes.string,
    imgSrc: PropTypes.string,
    altText: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    articleLink: PropTypes.string,
  }),
};

const PressReleases = () => {
  const isMobile = useResponsive('down', 'md');
  return (
    <RootStyle>
      <Container maxWidth="xl">
        <Box sx={{ mb: { xs: 1, md: 1 } }}>
          <Typography
            variant="h3"
            sx={{ textAlign: 'center', fontSize: { xs: 24, md: 34 }, fontWeight: { xs: 'bold' } }}
          >
            CREOATE in the press
          </Typography>
        </Box>
        {isMobile ? (
          <Container maxWidth="100%">
            <MobileCarousel />
          </Container>
        ) : (
          <Grid container spacing={1}>
            {PRESSRELEASES.map((pressrelease) => (
              <PressReleasesCard key={pressrelease.id} pressreleases={pressrelease} />
            ))}
          </Grid>
        )}
      </Container>
    </RootStyle>
  );
};

export default PressReleases;
