import React from 'react';
import Head from 'next/head';
import PropTypes from 'prop-types';

const Seo = ({
  title = 'Creoate - Online Wholesale Marketplace for Independent Retailers and Brands',
  description = 'Discover and buy unique wholesale products online at Creoate. Shop wholesale homeware, home decor products, jewellery, fashion accessories, stationery, gifts, food, drinks, kids and baby products etc. from thousands of independent wholesale vendors.',
}) => {
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
    </Head>
  );
};

Seo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default Seo;
