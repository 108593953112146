const answer = [
  `CREOATE is a wholesale marketplace. We provide a platform for independent retailers to source stock from independent brands, without the cost and hassle of traditional methods. Order minimums are set by each brand, and start at just £75.</br></br> CREOATE doesn’t hold stock. When you place an order through our website, you’re buying directly from that brand.`,
  `You can only shop on CREOATE if you are a business looking to shop wholesale for resale, and we have a few questions in place at signup to check this.`,
  `Wholesale refers to the practice of retailers buying goods directly from suppliers or brands, and selling them on to the end consumer — essentially, wholesale for resale. While it can also refer to buying goods ‘in bulk’ at a lower price for personal consumption, here, we’ll always be talking about the first meaning. <a href="https://www.creoate.com/blog/what-is-wholesale" target="_blank" rel="noopener noreferrer"> Find out more</a>`,
  `If you’re a retailer, you can view the wholesale price of any item by creating an account. It’s quick and easy. <a href="https://www.creoate.com/signup/retailer/"> Create an account</a>`,
  `Free shipping is available for orders from £300. Otherwise, you’ll pay a small flat fee (from £8.99). The minimum order value for free shipping, and the flat fee for orders that don’t meet this, vary depending on where you’re based, and where your products are coming from. This will always be made clear to you at checkout. See a full breakdown <a href="https://helpcenter.creoate.com/how-much-is-shipping" target="_blank" rel="noopener noreferrer">here</a>.`,
  `We currently facilitate shipping to the UK, Europe and the US. More coming soon!`,
  `CREOATE helps you shop wholesale at a rhythm that works for retail. We offer a trade credit limit of up to £2,500, and the option to pay up to 60 days after you order, all with the aim of helping your cash flow. Buy Now Pay Later is optional; If you’d rather, you can always pay for your order upfront.`,
];
const question = [
  'What does CREOATE do?',
  'Who can shop on CREOATE?',
  'What is wholesale?',
  'How do I see wholesale prices?',
  'How does shipping work?',
  'Which countries does CREOATE ship to?',
  'How does Buy Now Pay Later work?',
];

const answerPCT = [
  'Permanent brick and mortar retailers are eligible, at our discretion, for 60 Day payments. For e-commerce buyers or temporary retail locations, account limits are assessed based on your trading history with us.',
  'You can shop and simply complete your preferred payment method at checkout. We will not charge you until your invoice is due.',
  'Yes, if you’d like to pay off your invoices sooner you can do so. Paying your invoices before your due date lets you add back the limit you used before.',
  'No, you can use your account limits in different orders. If you used a partial amount of your account limit, you can use the remaining credit for your next order. Remember, if you pay sooner you will be able to add back the limit you used before.',
  'You can apply for an additional limit increase at any time. We base the limits we provide on your current business information, with the goal of extending the right limit for your buying needs.',
];
const questionPCT = [
  'Who is eligible?',
  'How do I use my account limits?',
  'Can I pay my invoices before due date?',
  'Do I need to use all my trade account limits in one order?',
  'How I can apply for an increase to my account limit?',
];

const answerJoinAsABrand = [
  `CREOATE is a wholesale marketplace designed to help you launch or level up the wholesale side of your business. Sell wholesale directly to our network of over 250,000+ independent retailers in the UK, wider Europe and the USA, with no joining or ongoing fees. We even take care of shipping for you, free of charge.`,
  `There is no joining fee, monthly fee or listing fee to sell on CREOATE. We only take a commission on sales: 20% on a retailer’s first order with you, 15% on reorders (excl. VAT). You don’t pay commission on orders from your existing stockists which are made through CREOATE.`,
  `We cover the cost of shipping for every order you receive. Simply book your shipping and collection via our platform – all you have to do is print the labels.`,
  `You’ll be paid for your order within 30 days of fulfilling it, and we also offer retailers 60-day net terms. This means you can offer your stockists better payment terms, without affecting your own cash flow.`,
  `Yes, you can still sell on CREOATE if you sell on other platforms.`,
  `Yes, you can set a minimum order quantity for any product as well as a general minimum order value for your brand.`,
  `We have over 250,000+ active buyers shopping on CREOATE from across the UK, wider Europe, Canada and the US.`,
  `We accept brands that ship from the UK, wider Europe and the US. If you can’t or would rather not sell in specific countries, you can restrict them from your dashboard.`,
];
const questionJoinAsABrand = [
  'Why should I sell on CREOATE?',
  'How much does it cost to sell on CREOATE?',
  'How does shipping work?',
  'How does payment work?',
  'Can I join if I sell on Amazon, Etsy or other platforms?',
  'Can I set a minimum order quantity and/or minimum order value?',
  'Where are CREOATE’s retail customers based?',
  'Can I choose the countries I sell to?',
];

export const faqs = question.map((_, index) => ({
  id: `panel${index + 1}bh-content`,
  value: `panel${index + 1}`,
  heading: question[index],
  detail: answer[index],
}));

export const faqsPaymentCreditTerms = questionPCT.map((_, index) => ({
  id: `panel${index + 1}bh-content`,
  value: `panel${index + 1}`,
  heading: questionPCT[index],
  detail: answerPCT[index],
}));

export const faqsJoinAsABrand = questionJoinAsABrand.map((_, index) => ({
  id: `panel${index + 1}bh-content`,
  value: `panel${index + 1}`,
  heading: questionJoinAsABrand[index],
  detail: answerJoinAsABrand[index],
}));
